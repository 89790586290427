<script>
import Vue from 'vue'
import { readableNameFromResourceClass } from '@sigmacloud/sigma-client/dist/util/resources/name'
import modelMap from '@sigmacloud/sigma-client/dist/util/resources/model-relationships'
import { toCamelCase } from '@sigmacloud/sigma-client/dist/util/string'

export default Vue.extend({
    props: {
        instanceName: {
            type: String,
            required: true,
        },
        resourceClassName: {
            type: String,
            required: true,
        },
        linkPrimaryKey: {
            type: String,
            default: 'id',
        },
        linkColumns: {
            type: Array,
            default() {
                return []
            },
        },
    },

    computed: {
        readableResourceClass: ({ resourceClassName }) => readableNameFromResourceClass(resourceClassName),
        pageTitle: ({ readableResourceClass }) => readableResourceClass,
        camelCaseResource: ({ readableResourceClass }) => toCamelCase(readableResourceClass),
        listFields: ({ $resources, resourceClassName }) => $resources[resourceClassName].getListset(),
        listRelatedFields: ({ listFields }) => listFields.filter((field) => Object.getPrototypeOf(field).constructor.name === 'ResourceRelatedField'),
        createRouteExists: ({ $router, camelCaseResource }) => $router.getRoutes().find((route) => route.name === `${camelCaseResource}Create`),
    },

    methods: {
        routeName(resourceClassName) {
            let readableResourceClassName = readableNameFromResourceClass(resourceClassName)
            let camelCaseResource = toCamelCase(readableResourceClassName)
            return `${camelCaseResource}Detail`
        },
        goToResource(resource, attributeLink = null) {
            let resourceClassName = attributeLink ? modelMap[resource.managers[attributeLink].to.MODEL_NAME].resourceClassName : this.resourceClassName

            return this.$router.push({
                name: this.routeName(resourceClassName),
                params: { instanceName: this.instanceName, id: resource.attributes[attributeLink || this.linkPrimaryKey] },
            })
        },
    },
})
</script>

<template>
    <resource-list
        v-page-title="pageTitle"
        :resource-class="$resources[resourceClassName]"
        :fields="listFields"
        :title="pageTitle"
        :filters="listFilters"
        :actions="listActions">
        <template v-if="createRouteExists" #filters-after>
            <b-button class="ml-auto" :to="{ name: `${camelCaseResource}Create`, props: { instanceName } }"> Add {{ readableResourceClass }} </b-button>
        </template>

        <template v-for="column in linkColumns" #[`value-${column}`]="{ resource }">
            <resource-format :key="column" :display-template="column" :resource="resource" linked @click="goToResource(resource)" />
        </template>

        <template v-for="related in listRelatedFields" #[`value-${related.key}`]="{ resource }">
            <resource-related-format
                :key="related.key"
                :display-template="related.key"
                :resource="resource"
                linked
                @click="goToResource(resource, related.attributeLink)" />
        </template>
    </resource-list>
</template>
