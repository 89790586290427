<script>
import Vue from 'vue'
import BaseList from '../BaseList'
import { ListFilterSelect, ListFilterRange, ListFilterDateRange, ResourceListAction } from '@sigmacloud/sigma-client/dist/components/core/resource_list'

export default Vue.extend({
    extends: BaseList,
    data() {
        return {
            listActions: [
                new ResourceListAction('Print Checks (Not Functional)', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                return true || payroll
                            })
                        ),
                }),
            ],
        }
    },
    computed: {
        listFilters() {
            return [
                new ListFilterSelect('Status', 'status', {
                    value: ['DRAFT', 'OPEN', 'AUDITED', 'POSTED', 'CLOSED', 'VOIDED', 'DELETED'],
                    choices: [
                        { value: 'DRAFT', text: 'Draft' },
                        { value: 'OPEN', text: 'Open' },
                        { value: 'AUDITED', text: 'Audited' },
                        { value: 'POSTED', text: 'Posted' },
                        { value: 'CLOSED', text: 'Closed' },
                        { value: 'VOIDED', text: 'Voided' },
                        { value: 'DELETED', text: 'Deleted' },
                    ],
                    many: true,
                }),
                new ListFilterRange('ID', 'id'),
                new ListFilterDateRange('Pay Period Start Date', 'pay_period_start_date'),
                new ListFilterDateRange('Pay Period End Date', 'pay_period_end_date'),
                new ListFilterDateRange('Due Date', 'date_due'),
                new ListFilterDateRange('Check Date', 'check_date'),
            ]
        },
        pageTitle() {
            return 'Printable Payrolls'
        },
    },
})
</script>

<template>
    <resource-list
        v-page-title="pageTitle"
        :resource-class="$resources[resourceClassName]"
        :fields="listFields"
        :title="pageTitle"
        :filters="listFilters"
        :actions="listActions"
        page-getter-method="printable">
        <template v-for="column in linkColumns" #[`value-${column}`]="{ resource }">
            <resource-format
                :key="column"
                :display-template="column"
                :resource="resource"
                linked
                @click="
                    $router.push({
                        name: `${camelCaseResource}Detail`,
                        params: { instanceName, id: resource.attributes[linkPrimaryKey] },
                    })
                " />
        </template>
    </resource-list>
</template>
